import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function BlockThankYou({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 24}
      height={props.size ?? 24}
      viewBox="-2 -2 26 26"
      role="img"
      aria-label="Thank you"
    >
      <path
        d="M9.21951 12C11.3167 12 13.0544 10.2188 13.0544 8C13.0544 5.8125 11.3167 4 9.21951 4C7.09237 4 5.38467 5.8125 5.38467 8C5.38467 10.2188 7.09237 12 9.21951 12ZM11.8859 13H11.3766C10.7175 13.3437 9.99847 13.5 9.21951 13.5C8.44056 13.5 7.69157 13.3437 7.03246 13H6.52314C4.30612 13 2.50854 14.9063 2.50854 17.2187V18.5C2.50854 19.3438 3.1377 20 3.94661 20H14.4924C15.2713 20 15.9305 19.3438 15.9305 18.5V17.2187C15.9305 14.9063 14.1029 13 11.8859 13ZM21.5629 9L20.724 8.12501C20.6042 7.96875 20.3645 7.96875 20.2447 8.12501L17.0989 11.375L15.7208 9.9375C15.6009 9.78125 15.3613 9.78125 15.2115 9.9375L14.3726 10.8125C14.2528 10.9375 14.2528 11.1875 14.3726 11.3437L16.8293 13.9063C16.979 14.0625 17.1888 14.0625 17.3386 13.9063L21.5629 9.53125C21.7127 9.37499 21.7127 9.15625 21.5629 9Z"
        fill={props.fill ?? "#66CC9A"}
      />
    </svg>
  );
}
