import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function BlockStandardFeedback({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 24}
      height={props.size ?? 24}
      viewBox="0 0 24 24"
      role="img"
      aria-label="Repeat Feedback"
    >
      <path
        d="M18.5 5H6.5C5.675 5 5 5.675 5 6.5V20L8 17H18.5C19.325 17 20 16.325 20 15.5V6.5C20 5.675 19.325 5 18.5 5ZM8 14V12.1475L13.16 6.9875C13.31 6.8375 13.5425 6.8375 13.6925 6.9875L15.02 8.315C15.17 8.465 15.17 8.6975 15.02 8.8475L9.8525 14H8ZM16.25 14H11.375L12.875 12.5H16.25C16.6625 12.5 17 12.8375 17 13.25C17 13.6625 16.6625 14 16.25 14Z"
        fill={props.fill ?? "#CB66CC"}
      />
    </svg>
  );
}
