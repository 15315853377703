import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function Tiktok({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" fill="white" />
        <path
          d="M16.6656 9.10769C17.937 10.016 19.4944 10.5505 21.1766 10.5505L21.1766 7.31519C20.8582 7.31526 20.5407 7.28208 20.2293 7.21613L20.2293 9.76276C18.5472 9.76276 16.99 9.22831 15.7183 8.32003L15.7183 14.9223C15.7183 18.2251 13.0395 20.9024 9.73519 20.9024C8.50228 20.9024 7.35635 20.5298 6.40442 19.8909C7.49089 21.0012 9.00605 21.69 10.6823 21.69C13.9868 21.69 16.6658 19.0128 16.6658 15.7098L16.6658 9.10769L16.6656 9.10769ZM17.8343 5.84368C17.1845 5.13421 16.7579 4.21735 16.6656 3.20373L16.6656 2.7876L15.7679 2.7876C15.9939 4.07592 16.7646 5.17658 17.8343 5.84368ZM8.49438 17.3564C8.13136 16.8807 7.93519 16.2987 7.93607 15.7004C7.93607 14.1898 9.16134 12.965 10.673 12.965C10.9547 12.9649 11.2347 13.008 11.5032 13.0932L11.5032 9.7856C11.1895 9.74262 10.8728 9.72438 10.5563 9.73107L10.5563 12.3055C10.2876 12.2204 10.0074 12.1771 9.72566 12.1774C8.21401 12.1774 6.98881 13.4021 6.98881 14.9129C6.98881 15.9811 7.60124 16.9059 8.49438 17.3564Z"
          fill="#FF004F"
        />
        <path
          d="M15.7183 8.31997C16.99 9.22824 18.5473 9.76269 20.2293 9.76269L20.2293 7.21606C19.2904 7.01617 18.4592 6.52578 17.8343 5.84368C16.7646 5.17651 15.9939 4.07585 15.7679 2.7876L13.4098 2.7876L13.4098 15.7097C13.4045 17.2161 12.1813 18.4359 10.6729 18.4359C9.784 18.4359 8.99432 18.0125 8.4942 17.3564C7.60113 16.9059 6.98869 15.981 6.98869 14.9129C6.98869 13.4023 8.2139 12.1775 9.72555 12.1775C10.0152 12.1775 10.2943 12.2226 10.5562 12.3056L10.5562 9.73113C7.30995 9.79817 4.69919 12.4493 4.69919 15.7098C4.69919 17.3374 5.34932 18.8129 6.40451 19.891C7.35644 20.5298 8.50237 20.9024 9.73528 20.9024C13.0396 20.9024 15.7184 18.225 15.7184 14.9223L15.7184 8.31997L15.7183 8.31997Z"
          fill="black"
        />
        <path
          d="M20.2292 7.21605L20.2292 6.52746C19.3826 6.52874 18.5526 6.29176 17.8343 5.8436C18.4701 6.53942 19.3075 7.0192 20.2292 7.21605ZM15.7679 2.78759C15.7463 2.66447 15.7298 2.54053 15.7183 2.41613L15.7183 2L12.4624 2L12.4624 14.9222C12.4572 16.4285 11.2341 17.6483 9.72552 17.6483C9.28263 17.6483 8.86448 17.5432 8.49417 17.3565C8.99429 18.0124 9.78397 18.4359 10.6729 18.4359C12.1811 18.4359 13.4045 17.2162 13.4098 15.7098L13.4098 2.78759L15.7679 2.78759ZM10.5563 9.73112L10.5563 8.99807C10.2842 8.9609 10.0099 8.94225 9.73532 8.94239C6.4307 8.94232 3.75189 11.6197 3.75189 14.9222C3.75189 16.9927 4.80471 18.8175 6.40455 19.8908C5.34936 18.8128 4.69922 17.3372 4.69922 15.7097C4.69922 12.4493 7.30992 9.79816 10.5563 9.73112Z"
          fill="#00F2EA"
        />
      </svg>
    </>
  );
}
