import { createContext, Dispatch, useContext } from "react";
import { ParameterValues } from "./builder/types/parameters";
import { Spec } from "./builder/types/specification";
import { Action, State } from "./state";
import {
  BuilderAction,
  BuilderState,
  defaultBuilderState,
  MaybeBuilderState,
} from "./state/builder/state";

type Context = { dispatch: Dispatch<Action>; state?: State };

export const AppContext = createContext<Context>({ dispatch: () => null });

type BuilderContextData = {
  dispatch: Dispatch<BuilderAction>;
  state: MaybeBuilderState;
  xrefSpecs: { [key: string]: Spec | undefined };
  imageUploadUrl: string;
  parameters: ParameterValues;
  builderLocale: string;
  activityId: string;
};

export const BuilderContext = createContext<BuilderContextData>({
  dispatch: () => null,
  state: defaultBuilderState,
  xrefSpecs: {},
  imageUploadUrl: "",
  parameters: {},
  builderLocale: "en_GB",
  activityId: "",
});

export const useBuilderContext = (): {
  dispatch: Dispatch<BuilderAction>;
  state: BuilderState;
  xrefSpecs: { [key: string]: Spec | undefined };
  imageUploadUrl: string;
  parameters: ParameterValues;
  builderLocale: string;
  activityId: string;
} => {
  const { state, ...context } = useContext(BuilderContext);
  if (state === "Loading")
    throw new Error("Can't consume context before state is loaded.");

  return { state, ...context };
};
