/**
 * This module defines a generic Option type representing values
 * that might be 'undefined' along with various supporting functions.
 *
 * The functions in this module are intended to be imported qualified.
 */

import { Key } from "./utils";

export type Option<A> = A | undefined;

export const isJust = <A>(oa: Option<A>): oa is A => {
  return oa !== undefined && oa !== null;
};

const isNothing = <A>(oa: Option<A>): oa is undefined => {
  return oa === undefined;
};

export const fromOption = <A>(oa: Option<A>, def: A): A => {
  return isJust(oa) ? oa : def;
};

export const map = <A, B>(f: (a: A) => B, oa: Option<A>): Option<B> => {
  return isJust(oa) ? f(oa) : undefined;
};

// Copy of 'lookup' with a slightly different type signature
// to produce nicer types when looking up 'Option' values.
export const lookup_ = <V, T extends Record<Key, Option<V>>>(
  k: keyof T,
  obj: Option<T>,
): Option<V> => {
  return isJust(obj) ? obj[k] : undefined;
};

type Just<T> = T extends undefined ? never : T;

type AllJust<T> = {
  [P in keyof T]: Just<T[P]>;
};

const fieldsAreJust = <T>(obj: T): obj is AllJust<T> => {
  for (const k in obj) {
    if (isNothing(obj[k])) return false;
  }
  return true;
};

/**
 * Warning: You probably don't want to use this function as it only checks whether
 * existing fields are 'undefined'. Use 'sequenceObjectStrict' instead to force a
 * check against missing fields (i.e. fields marked '?') too.
 */
export const sequenceObject = <T>(obj: T): Option<AllJust<T>> => {
  return fieldsAreJust(obj) ? obj : undefined;
};

export const filterMap = <A, B>(xs: A[], f: (x: A) => Option<B>): B[] => {
  return xs.reduce<B[]>((acc, x) => {
    const y = f(x);
    return isJust(y) ? [...acc, y] : acc;
  }, []);
};
