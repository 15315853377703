import React from "react";
import { Icon } from "../../types";
export function BlockTiktok({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 64 64"
      >
        <path
          d="M47.0887 17.0221C44.3949 15.2662 42.4523 12.4556 41.8453 9.181C41.7149 8.47365 41.6421 7.74498 41.6421 7H33.0465L33.0327 41.4495C32.8885 45.3074 29.7132 48.4039 25.8217 48.4039C24.6115 48.4039 23.4728 48.1017 22.4695 47.5737C20.1695 46.3634 18.5956 43.9529 18.5956 41.1786C18.5956 37.1941 21.8374 33.9521 25.8204 33.9521C26.5641 33.9521 27.2777 34.075 27.9524 34.2857V25.5103C27.2539 25.415 26.544 25.356 25.8204 25.356C17.0969 25.356 10 32.4534 10 41.1786C10 46.5314 12.6737 51.2684 16.7546 54.133C19.3242 55.9377 22.4507 57 25.8217 57C34.5452 57 41.6421 49.9026 41.6421 41.1786V23.7093C45.0131 26.1286 49.1441 27.5546 53.6012 27.5546V18.9585C51.2009 18.9585 48.9648 18.2449 47.0887 17.0221Z"
          fill={props.fill ?? `currentColor`}
        />
      </svg>
    </>
  );
}
