/*
  Code for walking our config tree (both spec and value)
*/
import { Spec } from "./types/specification";
import {
  Value,
  ValueList,
  ValueEnum,
  ValueObject,
  ValuePredicate,
  ValuePredicates,
  listJsonToStruct,
} from "./types/value";
import { PathElement } from "./types/path";
import { Dispatch } from "react";
import {
  BackViewPath,
  BuilderActionTypes,
  SetErrors,
  SetSelectedTab,
  SetViewPath,
  ValidationError,
  SetCurrentlyViewedError,
  UpdatePreviewState,
  SetWarnings,
} from "../state/builder/state";
import { fromOption, lookup_, Option } from "./types/option";
import { isPredicatedValue, valueWithDefault } from "../utils";

/*
A Node is combination of a spec at a given place in our config tree, the equivalent
value at the node and an update function (lens)
*/
export interface ConfigNode<S extends Spec, V extends Value> {
  spec: S;
  value: V | undefined;
  lens: (x: Value | undefined) => void;
  level: number;
  uiLevel?: number;
}

function predicateJsonToStruct([id, deleted, expr, value]: ValuePredicate) {
  return {
    id: id,
    deleted: deleted,
    expression: expr,
    value: value,
  };
}
/*
  walkTree

  1. It navigates to a location in our spec+value tree based upon the path input.
  2. Ultimately it returns the spec and value at `path`, as well as an update function
     that can be used by the UI to change the values in the state.

*/

export function walkTree(
  spec: Spec,
  value: Value | undefined,
  path: PathElement[],
  updateFn: (x: Value | undefined) => void,
  xrefSpecs: { [key: string]: Spec | undefined },
  level: number,
  locale: string,
  uiLevel = 0,
): ConfigNode<Spec, Value> | undefined {
  // if (path[0] && path[0] != 'predicate_cat') {
  //     path = ['predicate_cat'].concat(path)
  // }

  const remainingPath = [...path];
  const nextStep = remainingPath.shift();

  if (isPredicatedValue(value || "")) {
    if (nextStep === undefined) {
      return { spec: spec, value: value, lens: updateFn, level: level };
    }

    const predicates = (value as ValuePredicates)._predicated.map(
      predicateJsonToStruct,
    );

    if (nextStep === "_predicated") {
      // eslint-disable-next-line no-console
      console.error("Unexpected path element '_predicated' encountered.");
    } else {
      const predicate = predicates.find((p) => p.id === nextStep);

      if (!predicate) {
        // eslint-disable-next-line no-console
        console.error(
          `Predicate with ID "${nextStep}" not found in predicates:`,
          predicates,
        );
        throw new Error("Expected predicate with id: " + nextStep);
      }

      const predicateUpdateFn = (updateId: string) => {
        return (newValue: Value | undefined) => {
          const updateValues = predicates.map((p) => {
            if (p.id === updateId) {
              return [p.id, p.deleted, p.expression, newValue];
            } else {
              return [p.id, p.deleted, p.expression, p.value];
            }
          });

          updateFn({ _predicated: updateValues } as Value);
        };
      };

      return walkTree(
        spec,
        predicate.value,
        remainingPath,
        predicateUpdateFn(nextStep as string),
        xrefSpecs,
        level + 1,
        locale,
        uiLevel,
      );
    }
  }

  switch (spec.type) {
    case "xref":
      if (spec.xref in xrefSpecs) {
        const recursiveSpec = xrefSpecs[spec.xref];
        if (!recursiveSpec)
          throw new Error("Spec does not exist for the given key");
        if (nextStep === undefined) {
          return {
            spec: recursiveSpec,
            value: valueWithDefault(locale, value, recursiveSpec),
            lens: updateFn,
            level,
            uiLevel,
          };
        } else {
          return walkTree(
            recursiveSpec,
            valueWithDefault(locale, value, recursiveSpec),
            path,
            updateFn,
            xrefSpecs,
            level + 1,
            locale,
            uiLevel,
          );
        }
      }
      throw new Error("xref spec not found");

    case "object": {
      const valueObject = fromOption(value as Option<ValueObject>, {});
      if (nextStep === undefined) {
        return { spec, value, lens: updateFn, level, uiLevel };
      }

      const nextSpec = spec.object[nextStep];
      const nextValue: Option<Value> = lookup_(nextStep, valueObject);

      // TODO: Might actually want to throw an error here to get a better message
      // since returning undefined from walkTree seems to need to error eventually
      // anyway.
      if (nextSpec === undefined) return undefined;

      const objectUpdateFn = (key: string) => {
        return function (newValue: Value | undefined) {
          valueObject[key] = newValue;
          updateFn(valueObject);
        };
      };

      return walkTree(
        nextSpec,
        valueWithDefault(locale, nextValue, nextSpec),
        remainingPath,
        objectUpdateFn(nextStep as string),
        xrefSpecs,
        level + 1,
        locale,
        spec.ui === "flatten" ? uiLevel + 1 : 0,
      );
    }
    case "list": {
      const listItems = fromOption(value as ValueList, []).map(
        listJsonToStruct,
      );

      const listUpdateFn = (updateId: string) => {
        return (newValue: Value | undefined) => {
          const updateValues = listItems.map((item) => {
            if (item.id === updateId) {
              return [item.id, item.deleted, newValue];
            } else {
              return [item.id, item.deleted, item.value];
            }
          });

          updateFn(updateValues as Value);
        };
      };

      if (nextStep === undefined) {
        return { spec, value, lens: updateFn, level, uiLevel };
      } else {
        const item = listItems.find((item) => item.id === nextStep);

        if (!item) throw new Error("Expected item with id: " + nextStep);

        return walkTree(
          spec.list,
          valueWithDefault(locale, item.value, spec.list),
          remainingPath,
          listUpdateFn(nextStep as string),
          xrefSpecs,
          level + 1,
          locale,
          uiLevel,
        );
      }
    }
    case "enum": {
      const enumValue = value as ValueEnum | undefined;

      if (nextStep === undefined) {
        return { spec, value, lens: updateFn, level, uiLevel };
      }

      const enumUpdateFn = (key: string) => {
        return (newValue: Value | undefined) => {
          const values = enumValue?._values ?? {};
          values[key] = newValue;

          updateFn({
            _choice: key,
            _values: values,
          });
        };
      };

      const choiceKey =
        enumValue?._choice ||
        spec.default?._choice ||
        Object.keys(spec.choices)[0];
      const choiceSpec = spec.choices[choiceKey];
      const choiceValue = enumValue?._values[choiceKey];

      if (choiceSpec === undefined) return undefined;

      return walkTree(
        choiceSpec,
        valueWithDefault(locale, choiceValue, choiceSpec),
        remainingPath,
        enumUpdateFn(choiceKey),
        xrefSpecs,
        level + 1,
        locale,
        uiLevel,
      );
    }
    default:
      if (nextStep === undefined) {
        return {
          spec,
          value: valueWithDefault(locale, value, spec),
          lens: updateFn,
          level,
          uiLevel,
        };
      } else {
        return walkTree(
          spec,
          valueWithDefault(locale, value, spec),
          remainingPath,
          updateFn,
          xrefSpecs,
          level + 1,
          locale,
          uiLevel,
        );
      }
  }
}

export function setViewPath(
  dispatch: Dispatch<SetViewPath>,
  viewPath: PathElement[],
): void {
  dispatch({
    type: BuilderActionTypes.SetViewPath,
    viewPath: viewPath,
  });
}

export function backViewPath(dispatch: Dispatch<BackViewPath>): void {
  dispatch({ type: BuilderActionTypes.BackViewPath });
}

export function setErrors(
  dispatch: Dispatch<SetErrors>,
  errors: ValidationError[],
): void {
  dispatch({ type: BuilderActionTypes.SetErrors, errors: errors });
}

export function setWarnings(
  dispatch: Dispatch<SetWarnings>,
  warnings: PathElement[][],
): void {
  dispatch({ type: BuilderActionTypes.SetWarnings, warnings: warnings });
}

export const setSelectedTab = (
  dispatch: Dispatch<SetSelectedTab>,
  path: PathElement[],
  ix: number,
): void => {
  dispatch({
    type: BuilderActionTypes.SetSelectedTab,
    path: path,
    ix: ix,
  });
};

export const updatePreviewState = (
  dispatch: Dispatch<UpdatePreviewState>,
  locale: string,
  version: string | undefined,
): void => {
  dispatch({
    type: BuilderActionTypes.UpdatePreviewState,
    locale: locale,
    version: version ?? "2.0.0",
  });
};

export const setCurrentlyViewedError = (
  dispatch: Dispatch<SetCurrentlyViewedError>,
  path: PathElement[],
): void => {
  dispatch({
    type: BuilderActionTypes.SetCurrentlyViewedError,
    path: path,
  });
};
