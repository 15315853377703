export enum ActionTypes {
  ShowNav,
  HideNav,
}

export type State = {
  navHidden: boolean;
};

export function defaultState(): State {
  return {
    navHidden: true,
  };
}

export type Action = ShowNavAction | HideNavAction;

export type ShowNavAction = {
  type: ActionTypes.ShowNav;
};

export type HideNavAction = {
  type: ActionTypes.HideNav;
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionTypes.ShowNav:
      return showNav(state);

    case ActionTypes.HideNav:
      return hideNav(state);

    default:
      return state;
  }
}

function showNav(state: State) {
  return {
    ...state,
    navHidden: false,
  };
}

function hideNav(state: State) {
  return {
    ...state,
    navHidden: true,
  };
}
