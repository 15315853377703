import { Dispatch } from "react";
import * as GQL from "../../graph/generated";
import {
  BuilderActionTypes,
  CacheChangesAction,
  ClearChangesList,
  InitState,
  PreviewDetails,
  PushChangesAction,
  SetPreviewDetailsAction,
  UpdatePreviewAction,
  Config,
  SetTemplatesAction,
  SetFontsAction,
  SetActivityFlowsAction,
  Fonts,
  SetBrandingAction,
} from "../../state/builder/state";
import { PathElement } from "./path";
import { Value, ValueListItem } from "./value";
import { Activity, Maybe, Template } from "../../graph/generated";
import { BrandingActionTypes, Branding } from "../../state/branding/state";
export type Change =
  | {
      op: ChangeOp;
      path: PathElement[];
      value: Value | null;
    }
  // TODO: Temporary fix since these changes expect a 'ValueListItem'
  // but said type isn't a member of 'Value'. We could add it but that
  // isn't necessarily correct. Perhaps we wrap the new element for "cons"
  // in a list to make a singleton then have config server extract it.
  // Either way this needs a little more consideration but this works for now.
  | {
      op: "list_cons";
      path: PathElement[];
      value: ValueListItem;
    }
  | {
      op: "moveBefore" | "moveAfter";
      path: PathElement[];
      value: [string, string];
    };

type ChangeOp =
  | "update"
  | "moveUp"
  | "moveDown"
  | "list_cons"
  | "predicate_cons"
  | "update_predicate"
  | "list_delete"
  | "moveBefore"
  | "moveAfter"
  | "add"
  | "addToEnd";

/*
Given a set of changes only keeps the latest change for a given
op / path pair (when the changes are concurrent)
*/
export function compressChanges(changes: Change[]): Change[] {
  if (changes.length < 2) return changes;
  const compressedChanges = [];

  for (let i = 0; i < changes.length; i++) {
    if (i === 0) {
      compressedChanges.push(changes[i]);
    } else {
      const equalOp = changes[i].op == changes[i - 1].op;
      const equalPaths =
        JSON.stringify(changes[i].path) === JSON.stringify(changes[i - 1].path);

      if (!(changes[i].op === "update" && equalOp && equalPaths)) {
        compressedChanges.push(changes[i]);
      }
    }
  }
  return compressedChanges;
}

export function pushChanges(
  dispatch: Dispatch<PushChangesAction>,
  changes: Change[],
): void {
  dispatch({
    type: BuilderActionTypes.PushChanges,
    changes: changes,
  });
}

export function initState(
  dispatch: Dispatch<InitState>,
  config: Config,
  preview: GQL.PreviewDetails,
  locale: string,
  activity: Activity | undefined,
): void {
  // TODO: Temporary fix until we can consolidate preview fetching for emails and landing pages
  const previewDetails: PreviewDetails = {
    id: preview.id,
    type: preview.type,
    url: preview.previewUrl ? new URL(preview.previewUrl) : null,
  };

  dispatch({
    type: BuilderActionTypes.InitState,
    config,
    previewDetails,
    locale,
    activity,
  });
}

export function cacheChanges(
  dispatch: Dispatch<CacheChangesAction>,
  value: Value | undefined,
): void {
  dispatch({
    type: BuilderActionTypes.CacheChanges | BrandingActionTypes.CacheChanges,
    value: value,
  });
}

export function clearChangesList(dispatch: Dispatch<ClearChangesList>): void {
  dispatch({
    type: BuilderActionTypes.ClearChangesList,
  });
}

export function setPreviewDetails(
  dispatch: Dispatch<SetPreviewDetailsAction>,
  previewDetails: PreviewDetails,
): void {
  dispatch({
    type: BuilderActionTypes.SetPreviewDetails,
    previewDetails: previewDetails,
  });
}

export function setTemplates(
  dispatch: Dispatch<SetTemplatesAction>,
  templates: Maybe<Array<Maybe<Template>>>,
): void {
  dispatch({
    type: BuilderActionTypes.SetTemplates,
    templates: templates,
  });
}

export function setFonts(
  dispatch: Dispatch<SetFontsAction>,
  fonts: Fonts,
): void {
  dispatch({
    type: BuilderActionTypes.SetFonts,
    fonts: fonts,
  });
}

export function setActivityFlows(
  dispatch: Dispatch<SetActivityFlowsAction>,
  activity_flows: string,
): void {
  dispatch({
    type: BuilderActionTypes.SetActivityFlows,
    activityFlows: activity_flows,
  });
}

export function setBranding(
  dispatch: Dispatch<SetBrandingAction>,
  branding: Branding,
): void {
  dispatch({
    type: BuilderActionTypes.SetBranding,
    branding: branding,
  });
}

export function updatePreview(dispatch: Dispatch<UpdatePreviewAction>): void {
  dispatch({ type: BuilderActionTypes.UpdatePreview });
}
