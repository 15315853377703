type ValueNumber = number;
type ValueString = string;
type ValueHtml = string | null;
type ValueImage = string;
type ValueUrl = string;
type ValueHex = string;
type ValueId = string;
type ValueBoolean = boolean;
type ValueConstant = string;
type ValueEnum = {
  _choice: string;
  _values: {
    [key: string]: Value | undefined;
  };
};
type ValueList = ValueListItem[];
type ValueListItem = [string, boolean, Value | undefined];
type ValueRange = [a?: number | undefined, b?: number | undefined];
interface ValueObject {
  [key: string]: Value | undefined;
}
type ValuePredicates = {
  _predicated: ValuePredicate[];
};

export function isValueEnum(val: Value): val is ValueEnum {
  return (
    typeof val === "object" &&
    val !== null &&
    "_choice" in val &&
    "_values" in val
  );
}

export type Value =
  | ValueBoolean
  | ValueString
  | ValueObject
  | ValueList
  | ValueEnum
  | ValuePredicate
  | ValueNumber
  | ValueImage
  | ValueHex
  | ValueUrl
  | ValueConstant
  | ValueRange
  | ValueHtml
  | ValuePredicates;

// id, deleted, predicate, value
type ValuePredicate = [string, boolean, unknown, Value];

export type {
  ValueString,
  ValueObject,
  ValuePredicate,
  ValueEnum,
  ValueNumber,
  ValueList,
  ValueListItem,
  ValueBoolean,
  ValueImage,
  ValueHex,
  ValueId,
  ValueUrl,
  ValuePredicates,
  ValueConstant,
  ValueHtml,
};

interface ListStruct {
  id: string;
  deleted: boolean;
  value: Value | undefined;
}

export function listJsonToStruct([
  id,
  deleted,
  value,
]: ValueListItem): ListStruct {
  return {
    id: id,
    deleted: deleted,
    value: value,
  };
}

// export interface ValuePredicateStruct {
//     id: string
//     deleted: boolean
//     expression: any
//     value: Value
// }

// export function predicateJsonToStruct([id, deleted, expr, value]: ValuePredicate): ValuePredicateStruct {
//     return {
//         id: id,
//         deleted: deleted,
//         expression: expr,
//         value: value,
//     }
// }
