import { Nullable, nullableToOption } from "./builder/types/nullable";
import {
  Predicate,
  Predicated,
  Spec,
  SpecConstant,
} from "./builder/types/specification";
import {
  Value,
  ValueConstant,
  ValueEnum,
  ValueHex,
} from "./builder/types/value";
import { map, Option } from "./builder/types/option";
import { PathElement } from "./builder/types/path";
import { HSLA } from "./types";
import { ConfigNode, walkTree } from "./builder/walkTree";
import { StateFragment } from "./builder/types/utils";
import { globalBackgroundPath } from "./components/BuilderIcon/iconLibrary";

type NotConstant<S extends Spec> = S extends SpecConstant ? never : S;

export const isConstant = (spec: Spec): spec is SpecConstant => {
  return spec.type == "constant";
};

export const isPredicated = <V extends Value>(
  x: Value | Predicated<V>,
): x is Predicated<V> => {
  return (
    typeof x === "object" &&
    x !== null &&
    Object.keys(x).includes("_predicated")
  );
};

export function isPredicatedValue(value: Value): boolean {
  return (
    value !== null &&
    typeof value === "object" &&
    "_predicated" in value &&
    Array.isArray(value._predicated)
  );
}

export const unpredicate = <V extends Value>(
  locale: string,
  ps: Array<Predicate<V>>,
): Option<V> => {
  if (ps.length === 0) return undefined;
  const k = locale + "-default";
  return (ps.find((x) => x[0] == k) ?? ps[0])[3];
};

/**
 * If the value is required and undefined then apply the default otherwise return as is.
 *
 * We do NOT want to force a default if one does not exist since optional values should
 * be allowed to be blank and required values should be left undefined so that an appropriate
 * error can be shown.
 */
export const valueWithDefault_ = <V extends Value>(
  locale: string,
  val: Option<V>,
  spec: NotConstant<Spec>,
): Option<V> => {
  if (spec.type === "sourced_enum") return val;

  // TODO: Once we have type labels inside Values like we have on Specs we can get rid of the 'as'.
  // TODO: We can reinstate the optional check once we correctly handle the difference between a value
  // not existing and a value being deliberately made empty by the user.
  // A value not existing means not initialised and therefore the default is inserted.
  // A value being "Nothing" means it was deliberately made empty by the user and therefore
  // should not be filled in. If optional then the user is allowed to leave it empty otherwise
  // a "field required" error should be returned by config server.
  const def: Nullable<V> =
    // TODO Once we re-implement predicates properly we can get rid of this mess.
    typeof spec.default === "object" &&
    spec.default !== null &&
    isPredicated(spec.default)
      ? (unpredicate(locale, spec.default._predicated) as Nullable<V>)
      : (spec.default as V);
  return val ?? nullableToOption(def);
};

export const translateConstant = (
  locale: string,
  { value }: SpecConstant,
): Option<ValueConstant> => {
  return isPredicated(value) ? unpredicate(locale, value._predicated) : value;
};

export const valueWithDefault = <V extends Value>(
  locale: string,
  val: Option<V>,
  spec: Spec,
): Option<V | ValueConstant> => {
  return isConstant(spec)
    ? translateConstant(locale, spec)
    : valueWithDefault_(locale, val, spec);
};

export const sliceOn = <T>(x: T, xs: T[]): T[] => {
  const ix = xs.indexOf(x);
  return ix === -1 ? xs : xs.slice(0, ix + 1);
};

export const toTitleCase = (x: string): string =>
  x.replace(/\b\S/g, (t) => t.toUpperCase());

export const keyToTitle = (k: string): string =>
  toTitleCase(k.replaceAll("_", " "));

export function pathMatch(pathX: PathElement[], pathY: PathElement[]): boolean {
  if (pathX.length !== pathY.length) return false;

  for (const i of pathX.keys()) {
    if (pathX[i] === pathY[i] || pathY[i] === "*") continue;

    return false;
  }

  return true;
}

export function pathIsPrefix(
  prefix: PathElement[],
  path: PathElement[],
): boolean {
  if (prefix.length > path.length) return false;

  for (const i of prefix.keys()) {
    if (prefix[i] === path[i] || path[i] === "*") continue;

    return false;
  }

  return true;
}

// Values for s and l must be in percentage format e.g. 10 == 10% rather than 0.1
// https://stackoverflow.com/a/44134328/7720738
const hslToHex = (h: number, s: number, l: number): string => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
};

export const hslaToHex = (hsla: HSLA): string => {
  return hslToHex(hsla.h, hsla.s, hsla.l);
};

export function hslaToTransparentHex(
  h: number,
  s: number,
  l: number,
  a: string | number,
) {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r: string | number = 0;
  let g: string | number = 0;
  let b: string | number = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  a = Math.round(Number(a) * 255).toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;
  if (a.length == 1) a = "0" + a;

  return "#" + r + g + b + a;
}

// https://css-tricks.com/converting-color-spaces-in-javascript/#aa-hex-to-hsl
export const hexToHSL = (hex: string): HSLA => {
  hex = hex[0] === "#" ? hex : "#" + hex;

  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length == 4) {
    r = ("0x" + hex[1] + hex[1]) as unknown as number;
    g = ("0x" + hex[2] + hex[2]) as unknown as number;
    b = ("0x" + hex[3] + hex[3]) as unknown as number;
  } else if (hex.length == 7) {
    r = ("0x" + hex[1] + hex[2]) as unknown as number;
    g = ("0x" + hex[3] + hex[4]) as unknown as number;
    b = ("0x" + hex[5] + hex[6]) as unknown as number;
  }

  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  let h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h: h, s: s, l: l, a: 1 };
};

export const hslaToCSS = (hsla: HSLA): string => {
  return "hsla(" + hsla.h + "," + hsla.s + "%," + hsla.l + "%," + hsla.a + ")";
};

export const isValidHex = (hex: string): boolean => {
  return /^#([a-fA-F0-9]{6})$/.test(hex);
};

export const extractHSLA = (
  node: ConfigNode<Spec, Value>,
  state: StateFragment,
): HSLA | undefined => {
  const { spec, value } = node;

  const noLens = () => {
    /* No Lens - Read Only */
  };

  switch (spec.type) {
    case "hex":
      return map(hexToHSL, value as ValueHex);
    case "object":
      return specIsHSLA(spec) && value && isHSLA(value) ? value : undefined;
    case "xref": {
      const xrefNode = walkTree(
        spec,
        value,
        [],
        noLens,
        state.xrefSpecs,
        node.level,
        state.endUserLocale,
      );
      return map((n) => extractHSLA(n, state), xrefNode);
    }
    case "enum": {
      const enumValue = value as ValueEnum | undefined;
      const choiceKey = enumValue?._choice ?? spec.default?._choice;

      if (!choiceKey) return undefined;

      if (choiceKey === "global_background_colour") {
        const globalNode = walkTree(
          state.spec,
          state.value,
          globalBackgroundPath,
          noLens,
          state.xrefSpecs,
          0,
          state.endUserLocale,
        );
        return map((n) => extractHSLA(n, state), globalNode);
      } else {
        const choiceSpec = spec.choices[choiceKey];
        const choiceVal = enumValue?._values[choiceKey];

        if (!choiceSpec || !choiceVal) return undefined;

        const choiceNode = {
          spec: choiceSpec,
          value: choiceVal,
          lens: noLens,
          level: node.level + 1,
        };

        return extractHSLA(choiceNode, state);
      }
    }
    default:
      return undefined;
  }
};

export const recurseXref = (
  spec: Spec,
  xrefSpecs: { [key: string]: Spec | undefined },
): Spec => {
  if (spec.type == "xref") {
    const newSpec = xrefSpecs[spec.xref];
    if (!newSpec) throw new Error("Invalid xref");
    return recurseXref(newSpec, xrefSpecs);
  }
  return spec;
};

export const slugify = (x: string): string => {
  return x
    .normalize("NFKD")
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .trim()
    .replace(/[-\s]+/g, "_")
    .replace(/^[-_]+/, "")
    .replace(/[-_]+$/, "");
};

export const popEnum = (
  node: ConfigNode<Spec, Value>,
  xrefSpecs: { [key: string]: Spec | undefined },
  endUserLocale: string,
): ConfigNode<Spec, Value> => {
  const { spec, value, level } = node;

  if (spec.type !== "enum") return node;

  const noLens = () => {
    /* Read only */
  };

  const innerNode = walkTree(
    spec,
    value,
    [(value as ValueEnum)._choice],
    noLens,
    xrefSpecs,
    level,
    endUserLocale,
  );

  return innerNode ?? node;
};

export function featureEnabled(features: string[], feature: string) {
  return !!(feature && features.includes(feature));
}

export const isHSLA = (obj: Value): obj is HSLA => {
  return (
    typeof obj === "object" &&
    obj != null &&
    "h" in obj &&
    "s" in obj &&
    "l" in obj &&
    "a" in obj
  );
};

export const specIsHSLA = (spec: Spec): boolean => {
  if (spec.type !== "object") return false;

  const obj = spec.object;
  const h = "h" in obj && obj.h?.type === "number";
  const s = "s" in obj && obj.s?.type === "number";
  const l = "l" in obj && obj.l?.type === "number";
  const a = "a" in obj && obj.a?.type === "number";
  return h && s && l && a;
};
