import { ApolloProvider } from "@apollo/client";
import { ToastProvider } from "@sopost/react-components/dist/components-v2/Toast";
import "@sopost/react-components/dist/styles/globals.css";
import { AppProps } from "next/app";
import Head from "next/head";
import { Layout } from "../src/components/Layout";
import { createGraphClient } from "../src/graph/client";
import "../styles/animation.css";
import "../styles/globals.css";
import "../styles/helpers.css";
import "../styles/ui.css";

function Manager({ Component, pageProps }: AppProps): JSX.Element {
  /**
   * WARNING: Double check that 'pageProps' actually has the fields
   * that you're asking for. Unfortunately the type in NextJS is 'any'
   * so it could contain anything from half a potato to a fully built 747.
   */
  const GraphClient = createGraphClient(pageProps.GRAPHQL_URL);

  return (
    <ApolloProvider client={GraphClient}>
      <Head>
        <title>SoPost - Campaign Setup</title>
        <meta name="color-scheme" content="dark light"></meta>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      </Head>

      <ToastProvider
        opts={{
          position: "top-left",
          constraints: { top: "59px", left: "0px" },
        }}
      >
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ToastProvider>
    </ApolloProvider>
  );
}
export default Manager;
