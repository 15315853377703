import { Change, compressChanges } from "../../builder/types/change";
import { PathElement } from "../../builder/types/path";
import { Value, ValueEnum, ValueObject } from "../../builder/types/value";
import { HSLA } from "../../types";

import {
  BuilderActionTypes,
  Config,
  Fonts,
  ValidationError,
} from "../builder/state";

export type BrandingState = {
  currentConfig: string;
  configs: { [key: string]: Config };
  type: string;
  sidebarHidden: boolean;
  changes: Change[];
  errors: ValidationError[];
  viewPath: PathElement[];
  viewPathHistory: Array<PathElement[]>;
  showAdvancedFields: boolean;
  currentlyViewedError?: PathElement[];
  environment: { locale: "en_GB" };
  fonts: Fonts;
};

export type Logo = {
  width: number;
  height: number;
  natural_height: number;
  natural_width: number;
  image: string;
  size?: number;
  alt_text: string;
};

export type ImageV2 = {
  hyperlink: string;
  width: number;
  natural_height: number;
  natural_width: number;
  image: string;
  alt_text: string;
};

export type Buttons = {
  radius?: number | undefined;
  border:
    | {
        width: number;
        radius: number;
        color: string;
      }
    | undefined;
  color: string | undefined;
  background: string | undefined;
  font_family: ValueEnum | undefined;
};

export type Forms = {
  input:
    | {
        color: string | undefined;
        placeholder_color: string | undefined;
        background_color: string | undefined;
        border:
          | {
              width: number;
              radius: number;
              color: string;
            }
          | undefined;
      }
    | undefined;
  label:
    | {
        color: string | undefined;
        font_family: ValueEnum | undefined;
      }
    | undefined;
};

type Hero = {
  width: number;
  height: number;
  natural_height: number;
  natural_width: number;
  image: string;
  alt_text: string;
};

type CoverImage = {
  image: string;
};

export type Branding = {
  logo?: Logo;
  id?: string;
  spec?: Record<string, unknown>;
  typography?: Typography;
  page_background?: ValueEnum;
  block_background?: HSLA;
  hero?: Hero;
  buttons?: Buttons;
  cover_image?: CoverImage;
  email_background?: string;
  email_block_background?: string;
  forms?: Forms;
};

export type Typography = {
  titles: TypographyItem | undefined;
  body: TypographyItem | undefined;
  email_titles: TypographyItem | undefined;
  email_body: TypographyItem | undefined;
};

export type TypographyItem = {
  color?: string;
  font_family?: ValueEnum;
  font_properties?: ValueObject;
  hyperlink_color?: string;
};

export enum BrandingActionTypes {
  InitState,
  PushChanges,
  CacheChanges,
  SetViewPath,
  ClearChangesList,
  SetErrors,
  SetCurrentlyViewedError,
}

export type BrandingAction =
  | InitState
  | PushChangesAction
  | CacheChangesAction
  | SetViewPath
  | ClearChangesList
  | SetErrors
  | SetCurrentlyViewedError;

type InitState = {
  type: BrandingActionTypes.InitState;
  config: Config;
  fonts?: Fonts;
};

type PushChangesAction = {
  type: BrandingActionTypes.PushChanges;
  changes: Change[];
};

type CacheChangesAction = {
  type: BrandingActionTypes.CacheChanges | BuilderActionTypes.CacheChanges;
  value: Value | undefined;
};

type SetViewPath = {
  type: BrandingActionTypes.SetViewPath;
  viewPath: PathElement[];
};

type ClearChangesList = {
  type: BrandingActionTypes.ClearChangesList;
};

type SetErrors = {
  type: BrandingActionTypes.SetErrors;
  errors: ValidationError[];
};

type SetCurrentlyViewedError = {
  type: BrandingActionTypes.SetCurrentlyViewedError;
  path: PathElement[];
};

export function brandingReducer(
  state: BrandingState | "Loading",
  action: BrandingAction,
): BrandingState | "Loading" {
  switch (action.type) {
    case BrandingActionTypes.InitState:
      return initState(action.config, action.fonts ?? []);

    case BrandingActionTypes.PushChanges:
      if (state === "Loading") return state;
      return pushChanges(state, action.changes);

    case BrandingActionTypes.CacheChanges:
      if (state === "Loading") return state;
      return cacheChanges(state, action.value);

    case BrandingActionTypes.SetViewPath:
      if (state === "Loading") return state;
      return setViewPath(state, action.viewPath);

    case BrandingActionTypes.SetCurrentlyViewedError:
      if (state === "Loading") return state;
      return setCurrentlyViewedError(state, action.path);

    case BrandingActionTypes.ClearChangesList:
      if (state === "Loading") return state;
      return clearChangesList(state);

    case BrandingActionTypes.SetErrors:
      if (state === "Loading") return state;
      return setErrors(state, action.errors);

    default:
      return state;
  }
}

function initState(config: Config, fonts: Fonts): BrandingState {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    currentConfig: config.id,
    configs: { [config.id]: { ...config } },
    type: "",
    sidebarHidden: false,
    changes: [],
    errors: [],
    viewPath: [],
    viewPathHistory: [],
    showAdvancedFields: urlParams.get("show_advanced") === "true",
    environment: { locale: "en_GB" },
    fonts: fonts,
  };
}

/*
pushChanges adds a set of changes to an existing list of changes
in our state. When we submit changes for saving or preview, this
complete set of changes will be sent.
*/
function pushChanges(state: BrandingState, changes: Change[]): BrandingState {
  window.onbeforeunload = () => true; // Prompt user on navigation
  return {
    ...state,
    changes: compressChanges([...changes, ...state.changes]),
  };
}

/*
cacheChanges updates the `value` field of the builder with the
latest latest changes applied to the in-memory copy of the config.
On every change pushed to pushChanges, you could in theory rebuild
this purely from the list of changes, but it is calculated readily
with recursive update functions and we can think of it as a cache.
*/
function cacheChanges(
  state: BrandingState,
  value: Value | undefined,
): BrandingState {
  const { currentConfig, configs } = state;
  return {
    ...state,
    configs: {
      ...state.configs,
      [currentConfig]: {
        ...configs[currentConfig],
        value,
      },
    },
  };
}

function setViewPath(state: BrandingState, path: PathElement[]): BrandingState {
  return {
    ...state,
    viewPath: path,
    viewPathHistory: [state.viewPath, ...state.viewPathHistory],
  };
}

function setCurrentlyViewedError(
  state: BrandingState,
  path: PathElement[],
): BrandingState {
  return {
    ...state,
    currentlyViewedError: path,
  };
}

function setErrors(
  state: BrandingState,
  errors: ValidationError[],
): BrandingState {
  return {
    ...state,
    errors: errors,
  };
}

function clearChangesList(state: BrandingState): BrandingState {
  window.onbeforeunload = null; // Disable navigation prompt
  return {
    ...state,
    changes: [],
  };
}
