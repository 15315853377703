import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function JourneyNavigator({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 40}
      height={props.size ?? 40}
      viewBox="0 0 40 40"
      role="img"
      {...props}
    >
      <path
        d="M26.125 18.7853H23.1437C22.75 17.7709 21.7188 17.0364 20.5 17.0364C19.2812 17.0364 18.25 17.7709 17.8563 18.7853H14.875C14.5656 18.7853 13 18.6979 13 17.0364V16.1619C13 14.5616 14.4437 14.4129 14.875 14.4129H24.4188C24.8125 15.4273 25.8438 16.1619 27.0625 16.1619C27.8084 16.1619 28.5238 15.8855 29.0512 15.3935C29.5787 14.9015 29.875 14.2342 29.875 13.5384C29.875 12.8426 29.5787 12.1754 29.0512 11.6834C28.5238 11.1914 27.8084 10.915 27.0625 10.915C25.8438 10.915 24.8125 11.6495 24.4188 12.6639H14.875C13.3656 12.6639 11.125 13.5909 11.125 16.1619V17.0364C11.125 19.6074 13.3656 20.5343 14.875 20.5343H17.8563C18.25 21.5487 19.2812 22.2833 20.5 22.2833C21.7188 22.2833 22.75 21.5487 23.1437 20.5343H26.125C26.4344 20.5343 28 20.6218 28 22.2833V23.1578C28 24.7581 26.5562 24.9067 26.125 24.9067H16.5813C16.1875 23.8923 15.1562 23.1578 13.9375 23.1578C13.1916 23.1578 12.4762 23.4342 11.9488 23.9262C11.4213 24.4181 11.125 25.0854 11.125 25.7812C11.125 26.477 11.4213 27.1443 11.9488 27.6363C12.4762 28.1283 13.1916 28.4047 13.9375 28.4047C15.1562 28.4047 16.1875 27.6701 16.5813 26.6557H26.125C27.6344 26.6557 29.875 25.72 29.875 23.1578V22.2833C29.875 19.721 27.6344 18.7853 26.125 18.7853ZM27.0625 12.6639C27.3111 12.6639 27.5496 12.7561 27.7254 12.9201C27.9012 13.0841 28 13.3065 28 13.5384C28 13.7704 27.9012 13.9928 27.7254 14.1568C27.5496 14.3208 27.3111 14.4129 27.0625 14.4129C26.8139 14.4129 26.5754 14.3208 26.3996 14.1568C26.2238 13.9928 26.125 13.7704 26.125 13.5384C26.125 13.3065 26.2238 13.0841 26.3996 12.9201C26.5754 12.7561 26.8139 12.6639 27.0625 12.6639ZM13.9375 26.6557C13.6889 26.6557 13.4504 26.5636 13.2746 26.3996C13.0988 26.2356 13 26.0131 13 25.7812C13 25.5493 13.0988 25.3269 13.2746 25.1629C13.4504 24.9989 13.6889 24.9067 13.9375 24.9067C14.1861 24.9067 14.4246 24.9989 14.6004 25.1629C14.7762 25.3269 14.875 25.5493 14.875 25.7812C14.875 26.0131 14.7762 26.2356 14.6004 26.3996C14.4246 26.5636 14.1861 26.6557 13.9375 26.6557Z"
        fill={props.fill ?? "currentColor"}
      />
    </svg>
  );
}
