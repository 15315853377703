import React from "react";
import { Icon } from "../../types";
export function BlockYoutube({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 64 64"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.5347 14.8244C53.6858 15.4031 55.3801 17.1082 55.9551 19.2736C57 23.1981 57 31.3864 57 31.3864C57 31.3864 57 39.5745 55.9551 43.4992C55.3801 45.6645 53.6858 47.3696 51.5347 47.9485C47.6355 49 32 49 32 49C32 49 16.3646 49 12.4653 47.9485C10.3141 47.3696 8.61976 45.6645 8.04481 43.4992C7 39.5745 7 31.3864 7 31.3864C7 31.3864 7 23.1981 8.04481 19.2736C8.61976 17.1082 10.3141 15.4031 12.4653 14.8244C16.3646 13.7727 32 13.7727 32 13.7727C32 13.7727 47.6355 13.7727 51.5347 14.8244ZM26.8864 23.952V38.8207L39.9546 31.3867L26.8864 23.952Z"
          fill={props.fill ?? `currentColor`}
        />
      </svg>
    </>
  );
}
