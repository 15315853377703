/**
 * This module defines a generic Nullable type representing values that
 * might be 'null' or 'undefined' along with various supporting functions.
 *
 * The functions in this module are intended to be imported qualified.
 *
 * TODO: Rename things to make a bit more sense e.g. isComplete instead of sequenceObjectStrict.
 */

import { Maybe } from "./maybe";
import * as M from "./maybe";
import { Option } from "./option";
import * as O from "./option";
import { KeysEnum } from "./utils";

type Nothing = null | undefined;
export type Nullable<A> = A | Nothing;

export const isJust = <A>(na: Nullable<A>): na is A => {
  return na !== null && na !== undefined;
};

const isNothing = <A>(na: Nullable<A>): na is Nothing => {
  return na === null || na === undefined;
};

export const fromNullable = <A>(na: Nullable<A>, def: A): A => {
  return isJust(na) ? na : def;
};

export const map = <A, B>(f: (a: A) => B, na: Nullable<A>): Nullable<B> => {
  return isJust(na) ? f(na) : null;
};

export const when = <A>(na: Nullable<A>, f: (a: A) => void): void => {
  if (isJust(na)) f(na);
};

export type Complete<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

const fieldsAreJustStrict = <T>(
  obj: T,
  keys: KeysEnum<T>,
): obj is Complete<T> => {
  for (const k in keys) {
    if (isNothing(obj[k])) return false;
  }
  return true;
};

export const sequenceObjectStrict = <T>(
  obj: T,
  keys: KeysEnum<T>,
): Option<Complete<T>> => {
  return fieldsAreJustStrict(obj, keys) ? obj : undefined;
};

// Functions for converting between 'Maybe', 'Option' and 'Nullable'

export const optionToMaybe = <A>(oa: Option<A>): Maybe<A> => {
  return O.isJust(oa) ? oa : null;
};

export const maybeToOption = <A>(ma: Maybe<A>): Option<A> => {
  return M.isJust(ma) ? ma : undefined;
};

export const nullableToMaybe = <A>(na: Nullable<A>): Maybe<A> =>
  optionToMaybe(na);

export const nullableToOption = <A>(na: Nullable<A>): Option<A> =>
  maybeToOption(na);

export const collapse = <A>(na: Nullable<Maybe<A> | Option<A>>): Nullable<A> =>
  na;

export const filterNullable = <A>(xs: Nullable<A>[]): A[] => {
  return xs.filter((x) => isJust(x)) as A[];
};
