/**
 * This module defines a generic Maybe type representing values
 * that might be 'null' along with various supporting functions.
 *
 * The functions in this module are intended to be imported qualified.
 */

export type Maybe<A> = A | null;

export const isJust = <A>(ma: Maybe<A>): ma is A => {
  return ma !== null;
};

export const isNothing = <A>(ma: Maybe<A>): ma is null => {
  return ma === null;
};

export const map = <A, B>(f: (a: A) => B, ma: Maybe<A>): Maybe<B> => {
  return isJust(ma) ? f(ma) : null;
};
