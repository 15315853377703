import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { NormalizedCache } from "apollo-cache-inmemory";

function createLink(graphQLURL: string): ApolloLink {
  return createHttpLink({
    uri: graphQLURL,
    credentials: "include",
  });
}

export function createGraphClient(
  graphQL_URL: string,
): ApolloClient<NormalizedCache> {
  let link = createLink("http://localhost:4004/graphql");
  if (graphQL_URL) {
    link = createLink(graphQL_URL);
  }

  // TODO: Type error seems to be an apollo issue which may or
  // may not be fixed by updating the library:
  // https://github.com/apollographql/apollo-client/issues/2503
  // @ts-ignore
  return new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });
}
