import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function IconStarRating({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 24 24"
      >
        <path
          d="M8.09121 18.8901L12 16.8777L15.9088 18.8901C16.6026 19.2475 17.431 18.6734 17.2964 17.9029L16.5488 13.6408L19.7099 10.6236C20.2781 10.0816 19.9641 9.13547 19.1805 9.02416L14.8112 8.40022L12.8583 4.52178C12.5084 3.83047 11.4946 3.82169 11.1417 4.52178L9.18879 8.40022L4.81946 9.02416C4.0359 9.13547 3.72188 10.0816 4.29011 10.6236L7.45123 13.6408L6.70357 17.9029C6.56899 18.6734 7.39738 19.2504 8.09121 18.8901Z"
          fill={props.fill ?? `#846AED`}
        />
      </svg>
    </>
  );
}
