import { ReactNode, useReducer } from "react";
import { reducer, defaultState } from "./../../state";
import { AppContext } from "./../../context";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const [state, dispatch] = useReducer(reducer, defaultState());

  return (
    <>
      <AppContext.Provider value={{ dispatch, state }}>
        {children}
        <div id="modal" />
      </AppContext.Provider>
    </>
  );
};

export { Layout };
