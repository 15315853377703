import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function BlockPin({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 24}
      height={props.size ?? 24}
      viewBox="0 0 24 24"
      role="img"
      aria-label="Thank you"
    >
      <path
        d="M4.61429 14L4.67143 12.4722L3.34286 13.2917L3 12.7083L4.4 12L3 11.2917L3.34286 10.7083L4.67143 11.5278L4.61429 10H5.3L5.24286 11.5278L6.57143 10.7083L6.91429 11.2917L5.51429 12L6.91429 12.7083L6.57143 13.2917L5.24286 12.4722L5.3 14H4.61429Z"
        fill={props.fill ?? "currentColor"}
      />
      <path
        d="M9.64286 14L9.7 12.4722L8.37143 13.2917L8.02857 12.7083L9.42857 12L8.02857 11.2917L8.37143 10.7083L9.7 11.5278L9.64286 10H10.3286L10.2714 11.5278L11.6 10.7083L11.9429 11.2917L10.5429 12L11.9429 12.7083L11.6 13.2917L10.2714 12.4722L10.3286 14H9.64286Z"
        fill={props.fill ?? "currentColor"}
      />
      <path
        d="M14.6714 14L14.7286 12.4722L13.4 13.2917L13.0571 12.7083L14.4571 12L13.0571 11.2917L13.4 10.7083L14.7286 11.5278L14.6714 10H15.3571L15.3 11.5278L16.6286 10.7083L16.9714 11.2917L15.5714 12L16.9714 12.7083L16.6286 13.2917L15.3 12.4722L15.3571 14H14.6714Z"
        fill={props.fill ?? "currentColor"}
      />
      <path
        d="M19.7 14L19.7571 12.4722L18.4286 13.2917L18.0857 12.7083L19.4857 12L18.0857 11.2917L18.4286 10.7083L19.7571 11.5278L19.7 10H20.3857L20.3286 11.5278L21.6571 10.7083L22 11.2917L20.6 12L22 12.7083L21.6571 13.2917L20.3286 12.4722L20.3857 14H19.7Z"
        fill={props.fill ?? "currentColor"}
      />
    </svg>
  );
}
