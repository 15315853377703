import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function BlockYesNo({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 118}
      height={props.size ?? 84}
      viewBox="0 0 118 84"
      role="img"
      aria-label="Feedback"
    >
      <mask
        id="mask0_35_8972"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="28"
        y="30"
        width="24"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.4133 41.646C51.4133 47.7096 46.3575 52.6252 40.1207 52.6252C33.884 52.6252 28.8281 47.7096 28.8281 41.646C28.8281 35.5824 33.884 30.6669 40.1207 30.6669C46.3575 30.6669 51.4133 35.5824 51.4133 41.646ZM33.0485 42.8552L37.7841 47.4594C38.0687 47.736 38.53 47.736 38.8145 47.4594L47.1929 39.3136C47.4774 39.037 47.4774 38.5884 47.1929 38.3118L46.1626 37.3101C45.8781 37.0335 45.4168 37.0335 45.1322 37.3101L38.2993 43.9533L35.1092 40.8517C34.8247 40.5751 34.3634 40.5751 34.0789 40.8517L33.0485 41.8534C32.7641 42.13 32.7641 42.5786 33.0485 42.8552Z"
          fill="#66CC9A"
        />
      </mask>
      <g mask="url(#mask0_35_8972)">
        <rect
          x="23"
          y="25.0002"
          width="34.9707"
          height="34"
          fill={props.fill ?? "currentColor"}
          className="icon-highlight"
        />
      </g>

      <mask
        id="mask1_35_8972"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="66"
        y="31"
        width="24"
        height="22"
      >
        <path
          d="M77.8844 31.021C71.6462 31.021 66.5918 35.9351 66.5918 42.0002C66.5918 48.0653 71.6462 52.9793 77.8844 52.9793C84.1227 52.9793 89.1771 48.0653 89.1771 42.0002C89.1771 35.9351 84.1227 31.021 77.8844 31.021ZM83.3941 44.922C83.6218 45.0991 83.6218 45.4533 83.3941 45.6746L81.6183 47.4012C81.3906 47.6226 81.0263 47.6226 80.8442 47.4012L77.8844 44.4793L74.8791 47.4012C74.697 47.6226 74.3327 47.6226 74.105 47.4012L72.3292 45.6304C72.1015 45.4533 72.1015 45.0991 72.3292 44.8778L75.3345 42.0002L72.3292 39.1226C72.1015 38.9455 72.1015 38.5913 72.3292 38.37L74.1506 36.6434C74.3327 36.422 74.697 36.422 74.9247 36.6434L77.8844 39.521L80.8442 36.6434C81.0263 36.422 81.3906 36.422 81.6183 36.6434L83.3941 38.37C83.6218 38.5913 83.6218 38.9455 83.3941 39.1226L80.4344 42.0002L83.3941 44.922Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask1_35_8972)">
        <rect
          x="60.3994"
          y="25.0002"
          width="34.9707"
          height="34"
          fill={props.fill ?? "currentColor"}
          className="icon-highlight"
        />
      </g>
    </svg>
  );
}
