import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function IconDateQuestion({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 24 24"
      >
        <path
          d="M16.4132 7.75546L15.8763 7.75546L15.8763 7.21001C15.8763 6.91001 15.6347 6.66455 15.3395 6.66455C15.0442 6.66455 14.8026 6.91001 14.8026 7.21001L14.8026 7.75546L9.43421 7.75546L9.43421 7.21001C9.43421 6.91001 9.19263 6.66455 8.89737 6.66455C8.60211 6.66455 8.36053 6.91001 8.36053 7.21001L8.36053 7.75546L7.82368 7.75546C7.23316 7.75546 6.75 8.24637 6.75 8.84637L6.75 17.5736C6.75 18.1736 7.23316 18.6646 7.82368 18.6646L16.4132 18.6646C17.0037 18.6646 17.4868 18.1736 17.4868 17.5736L17.4868 8.84637C17.4868 8.24637 17.0037 7.75546 16.4132 7.75546ZM15.8763 17.5736L8.36053 17.5736C8.06526 17.5736 7.82368 17.3282 7.82368 17.0282L7.82368 10.4827L16.4132 10.4827L16.4132 17.0282C16.4132 17.3282 16.1716 17.5736 15.8763 17.5736Z"
          fill={props.fill ?? "currentColor"}
        />
      </svg>
    </>
  );
}
