import React from "react";
import type { Icon } from "@sopost/react-components/dist/types";
export function Meta({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 24 24"
      >
        <rect width="24" height="24" fill="white" />
        <path
          d="M5.00689 13.9137C5.00689 14.6036 5.15831 15.1332 5.35621 15.4536C5.61569 15.8733 6.0027 16.0511 6.39726 16.0511C6.90616 16.0511 7.37171 15.9248 8.26888 14.684C8.98762 13.6894 9.83453 12.2934 10.4044 11.4182L11.3694 9.9355C12.0398 8.90576 12.8157 7.76105 13.7053 6.98513C14.4316 6.35183 15.2151 6 16.0035 6C17.3273 6 18.5882 6.76712 19.5533 8.20586C20.6094 9.78157 21.1221 11.7663 21.1221 13.8145C21.1221 15.032 20.8821 15.9267 20.4737 16.6335C20.0791 17.3171 19.3101 18 18.0165 18V16.0511C19.1242 16.0511 19.4006 15.0333 19.4006 13.8685C19.4006 12.2086 19.0136 10.3665 18.161 9.05026C17.556 8.11665 16.7719 7.54617 15.9093 7.54617C14.9763 7.54617 14.2255 8.24984 13.3818 9.5045C12.9332 10.1711 12.4727 10.9835 11.9556 11.9001L11.3864 12.9085C10.2429 14.9359 9.95328 15.3977 9.38155 16.1598C8.37946 17.4942 7.52375 18 6.39726 18C5.06092 18 4.2159 17.4214 3.69255 16.5493C3.26532 15.8387 3.05548 14.9064 3.05548 13.844L5.00689 13.9137Z"
          fill="#0081FB"
        />
        <path
          d="M4.59412 8.34345C5.48877 6.96439 6.77987 6 8.26071 6C9.1183 6 9.97087 6.25382 10.8611 6.98074C11.835 7.7755 12.8729 9.08419 14.1677 11.241L14.632 12.0151C15.7529 13.8823 16.3906 14.8429 16.7637 15.2959C17.2437 15.8777 17.5799 16.0511 18.0165 16.0511C19.1242 16.0511 19.4006 15.0333 19.4006 13.8685L21.1221 13.8145C21.1221 15.032 20.8821 15.9267 20.4737 16.6335C20.0791 17.3171 19.3101 18 18.0165 18C17.2123 18 16.4999 17.8253 15.712 17.0821C15.1064 16.5116 14.3983 15.4982 13.8536 14.5872L12.2333 11.8806C11.4203 10.5223 10.6745 9.50952 10.2429 9.05089C9.77862 8.55769 9.18176 7.96209 8.2293 7.96209C7.45841 7.96209 6.80375 8.50304 6.2559 9.33047L4.59412 8.34345Z"
          fill="url(#paint0_linear_1_2)"
        />
        <path
          d="M8.2293 7.96209C7.45841 7.96209 6.80375 8.50304 6.2559 9.33047C5.48124 10.4997 5.00689 12.2413 5.00689 13.9137C5.00689 14.6036 5.15831 15.1332 5.35621 15.4536L3.69255 16.5493C3.26532 15.8387 3.05548 14.9064 3.05548 13.844C3.05548 11.912 3.58574 9.89842 4.59412 8.34345C5.48878 6.96439 6.77988 6 8.26071 6L8.2293 7.96209Z"
          fill="url(#paint1_linear_1_2)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_2"
            x1="6.88794"
            y1="13.3508"
            x2="19.3277"
            y2="13.9791"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0064E1" />
            <stop offset="0.4" stopColor="#0064E1" />
            <stop offset="0.83" stopColor="#0073EE" />
            <stop offset="1" stopColor="#0082FB" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_2"
            x1="5.8827"
            y1="14.733"
            x2="5.8827"
            y2="10.1466"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0082FB" />
            <stop offset="1" stopColor="#0064E0" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
